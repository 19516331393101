import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <path d="M36.669 29.53 20 39.16 3.331 29.53V10.47L20 .84l16.669 9.63v19.06Z" fill="#fff"></path>
      <path d="M20.006 2.75 5 11.378v17.25l15.006 8.622L35 28.628v-17.25L20.006 2.75Zm10.551 23.322-10.551 6.066-10.558-6.066V13.928l10.558-6.066 10.551 6.066v12.144Z" fill="#002D74"></path>
      <path d="m20.007 39.167 16.66-9.58V10.42L20.007.833V6.52l11.724 6.74v13.493l-11.724 6.734v5.68Z" fill="url(#cro_svg__a)" transform="matrix(.9 0 0 .9 2 2)"></path>
      <path d="m19.996.833-16.66 9.58V29.58l16.66 9.587V33.48L8.272 26.74V13.247l11.724-6.734V.833Z" fill="url(#cro_svg__b)" transform="matrix(.9 0 0 .9 2 2)"></path>
      <path d="M27.003 24.03 20 28.057l-7.01-4.025v-8.056l7.01-4.03 7.003 4.03-2.915 1.677L20 15.3l-4.089 2.353v4.696L20 24.702l4.088-2.354 2.915 1.683Z" fill="#002D74"></path>
      <defs>
        <linearGradient id="cro_svg__a" x1="28.337" y1="39.167" x2="28.337" y2="20" gradientUnits="userSpaceOnUse">
          <stop stop-color="#002D74"></stop>
          <stop offset="1" stop-color="#002D74" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="cro_svg__b" x1="11.666" y1="0.833" x2="11.666" y2="20" gradientUnits="userSpaceOnUse">
          <stop stop-color="#002D74"></stop>
          <stop offset="1" stop-color="#002D74" stop-opacity="0"></stop>
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;